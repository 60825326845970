import type { RejectionReason as UploadRejectionReason } from 'components/upload/utils';

enum StepRouteParam {
  CreateNewAccount = 'create-new-account',
  ConfirmEmailAddress = 'confirm-email-address',
  SelectCategory = 'select-category',
  UploadDocuments = 'upload-documents',
  ReviewAndFinish = 'review-and-finish',
}

type UIGender = 'female' | 'male';

type CategoryType = 'nude' | 'non-nude' | undefined;
type FaceVisibilityType = 'show' | 'hide' | undefined;
type SubCategoryType = 'girl' | 'mature' | 'fetish';

type RejectionReason = UploadRejectionReason | 'NoIdDetected' | 'NoFaceDetected' | 'FileRejected';

export {
  StepRouteParam,
  type UIGender,
  type CategoryType,
  type FaceVisibilityType,
  type SubCategoryType,
  type RejectionReason,
};
