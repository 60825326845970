import { defineMessages } from 'react-intl';

const seo = defineMessages({
  title: {
    id: 'pages.statistics.fanClubMembers.seo.title',
    defaultMessage: 'Fan Club Members',
  },
  description: {
    id: 'pages.statistics.fanClubMembers.seo.description',
    defaultMessage: 'Fan Club Members',
  },
});

const status = defineMessages({
  enabled: {
    id: 'pages.statistics.fanClubMembers.status.enabled',
    defaultMessage: 'Fan Club is enabled',
  },
  disabled: {
    id: 'pages.statistics.fanClubMembers.status.disabled',
    defaultMessage: 'Fan Club is disabled',
  },
});

const buttons = defineMessages({
  enable: {
    id: 'pages.statistics.fanClubMembers.buttons.enable',
    defaultMessage: 'Enable Fan Club',
  },
  moreInfo: {
    id: 'pages.statistics.fanClubMembers.buttons.moreInfo',
    defaultMessage: 'More info',
  },
});

const subscriptions = defineMessages({
  active: {
    id: 'pages.statistics.fanClubMembers.subscriptions.active',
    defaultMessage: 'Active Subscriptions',
  },
  total: {
    id: 'pages.statistics.fanClubMembers.subscriptions.total',
    defaultMessage: 'Total Subscriptions',
  },
});

const emptyState = defineMessages({
  title: {
    id: 'pages.statistics.fanClubMembers.emptyState.title',
    defaultMessage: 'No Fan Club subscribers yet',
  },
  searchTitle: {
    id: 'pages.statistics.fanClubMembers.emptyState.searchTitle',
    defaultMessage: 'No results found',
  },
  enabledDescription: {
    id: 'pages.statistics.fanClubMembers.emptyState.enabledDescription',
    defaultMessage: 'Encourage members to subscribe to your Fan Club for exclusive perks.',
  },
  searchDescription: {
    id: 'pages.statistics.fanClubMembers.emptyState.searchDescription',
    defaultMessage: 'Please try searching with different filters.',
  },
  disabledDescription: {
    id: 'pages.statistics.fanClubMembers.emptyState.disabledDescription',
    defaultMessage:
      'Encourage members to subscribe to your Fan Club for exclusive perks. <button>Activate</button> your Fan Club today to get started!',
  },
});

export default {
  pages: {
    statistics: {
      fanClubMembers: {
        seo,
        status,
        buttons,
        subscriptions,
        emptyState,
        navigationMenu: defineMessages({
          label: {
            id: 'pages.statistics.fanClubMembers.navigationMenu.label',
            defaultMessage: 'Fan Club Members',
          },
        }),
        ...defineMessages({
          totalMembers: {
            id: 'pages.statistics.fanClubMembers.totalMembers',
            defaultMessage: '{count} {count, plural, one {member} other {members}}',
          },
        }),
      },
    },
  },
};
